<template>
  <div class="StatusPagesNew">
    <div class="page-header">New Status Page</div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <status-page-form @submitSuccess="goToStatusPagesList" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusPageForm from '@/components/Forms/StatusPageForm/StatusPageForm.vue'

export default {
  components: {
    StatusPageForm
  },

  metaInfo: {
    title: 'New Status Page'
  },

  methods: {
    goToStatusPagesList (statusPage) {
      this.$router.push({
        name: 'statusPages.single.monitors',
        params: {
          id: statusPage.id
        }
      })
    }
  }
}
</script>
